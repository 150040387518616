/* <!-- ======= About Section ======= --> */


@font-face {
	font-family: "montserrat";
	// src: url(./fonts/Montserrat-VariableFont_wght.ttf);
}

* {
	margin: 0;
	padding: 0;
}

body {
	background: #0B0518;
	font-size: 1rem;
	letter-spacing: 0px;
	color: #FFFFFF;
	line-height: 1.5;
}

h1,
h2,
h3,
h4,
h5, button, a {
	font-family: "montserrat";
}

p {
	font-family: "montserrat";
}

h1 {
	font-size: 60px;
	
}

h2 {
	font-size: 32px;
	
}

h3 {
	font-size: 28px;
}

h4 {
	font-size: 18px;
	
	letter-spacing: 1.13px;
}

h5 {
	font-size: 16px;
	font-weight: bold;
}

a {
	cursor: pointer;
	text-decoration: none;
}

button {
	font-family: 'montserrat';
}

.home-btn-btn {
	background: transparent;
	color: #FFFFFF;
	border-radius: 30px;
	padding: 4px 4px 4px 16px;
	font-size: 1rem;
	line-height: 1.6;
	border: 2px solid #FFFFFF;
	text-decoration: none;
	display: flex;
	align-items: center;
    justify-content: center;
	 span{
		width: 50px;
		height: 50px;
		background: #fff;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 25px;
		margin-left: 10px;
	 }
}


/* ================== Navbar ================== */
.navbar {
	backdrop-filter: blur(50px);
	.navbar-brand{
		width: auto;
	}
}

.navbar .nav-item .nav-link,
.navbar .nav-item .nav-link.active {
	color: #FFFFFF;
	font-size: 1rem;
	padding: 0 30px;
}

.fa-arrow-right,
.fa-brands {
	background: #FFFFFF;
	border-radius: 50%;
	color: #0B0518;
}

.fa-arrow-right {
	padding: 7px;

}

.fa-brands {
	padding: 7px 8px;
	font-size: 1rem;
}
.brand{
    width: 150px;
}
.container{
	position: relative;
	z-index: 1;
}
.btn {
		display: inline-block;
		color: #ffffff;
		padding: 32px;
		position: relative;
		letter-spacing: 1px;
		width: auto;

		&__circle,
		&__text,
		&__white-circle {
			position: absolute;
		}

		&__circle {
			top: 0;
			left: 0;
			height: 100%;
			border-radius: 100%;
			width: 100%;
			box-shadow: 0 0 1px 1px #fff;
			transition: 0.3s linear;
		}

		&__white-circle {
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%) scale(0);
			width: 56px;
			height: 56px;
			border-radius: 100%;
			background: #ffffff;
			display: flex;
			transition: 0.3s ease-in-out;

			svg {
				width: 24px;
				height: 24px;
				margin: auto;
			}
		}

		&__text {
			top: 50%;
			transform: translateY(-50%);
			white-space: nowrap;
			z-index: 2;
			padding: 24px 8px;
			transition: 0.3s linear;
		}

		&:hover {
			.btn__circle {
				transform: scale(0);
			}

			.btn__white-circle {
				transform: translate(-50%, -50%) scale(1);
			}

			.btn__text {
				transform: translate(40px, -50%);
			}
		}
	}
	section{
		.row-1{
			opacity: 0;
			margin-top: 80px;
		}
		.row-2{
			opacity: 0;
			margin-top: 80px;
		}
		.row-3{
			opacity: 0;
			margin-top: 80px;
		}
		.row-4{
			opacity: 0;
			margin-top: 80px;
		}
		.row-5{
			opacity: 0;
			margin-top: 80px;
		}
	}
.banner-wrapper {
	// background: url('../../public/images/hero-banner-img.png');
	// background-position: 18% 95%;
	// background-size: 70%;
	// background-repeat: no-repeat;
	.banner-img{
		opacity: 0;
		margin-top: 50px;
	}
	padding: 150px 0px;
	position: relative;
	h1 {
		font-weight: 700;
		font-size: 80px;
		mix-blend-mode: overlay;
	}

	p {
		font-size: 16px;
		font-style: italic;
		width: 90%;
	}

	.btn {
		display: inline-block;
		color: #ffffff;
		padding: 32px;
		position: relative;
		letter-spacing: 1px;
		width: auto;

		&__circle,
		&__text,
		&__white-circle {
			position: absolute;
		}

		&__circle {
			top: 0;
			left: 0;
			height: 100%;
			border-radius: 100%;
			width: 100%;
			box-shadow: 0 0 1px 1px #fff;
			transition: 0.3s linear;
		}

		&__white-circle {
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%) scale(0);
			width: 56px;
			height: 56px;
			border-radius: 100%;
			background: #ffffff;
			display: flex;
			transition: 0.3s ease-in-out;

			svg {
				width: 24px;
				height: 24px;
				margin: auto;
			}
		}

		&__text {
			top: 50%;
			transform: translateY(-50%);
			white-space: nowrap;
			z-index: 2;
			padding: 24px 8px;
			transition: 0.3s linear;
		}

		&:hover {
			.btn__circle {
				transform: scale(0);
			}

			.btn__white-circle {
				transform: translate(-50%, -50%) scale(1);
			}

			.btn__text {
				transform: translate(40px, -50%);
			}
		}
	}

	.banner-img-1 {
		background: url('../../public/images/banner-img-1.png');
		width: 270px;
		position: absolute;
		height: 560px;
		top: 0;
		right: 0;
		background-repeat: no-repeat;
		background-position: left;
		background-size: cover;
	}

	.banner-img-2 {
		background: url('../../public/images/banner-img-2.png');
		bottom: 0;
		left: 0;
		background-repeat: no-repeat;
		background-position: left;
		background-size: contain;
		width: 270px;
		position: absolute;
		height: 560px;
	}
}

/* =================== card-wrapper ============ */
.card-wrapper {
	padding: 50px 0 30px;
}

.bg-box {
	background: #0B0518;
	border: 2px solid #FFFFFF12;
	padding: 8px 0;
	border-radius: 10px;

}

.card-box {
	border: 2px solid #FFFFFF45;
	border-radius: 10px;
	padding: 25px 25px 10px;
}

.card-box p {
	color: #BCBCBC;
}


.bg-border h4 {
	color: #FFFFFF !important;
	display: flex;
	align-items: center;
	justify-content: center;
}

.bg-border h4:before {
	content: "";
	width: 100%;
	height: 2px;
	background: transparent linear-gradient(270deg, #FFFFFF 0%, #80808000 100%) 0% 0% no-repeat padding-box;
	margin: 0 10px 0 0;
}

.bg-border h4:after {
	content: "";
	width: 100%;
	height: 2px;
	background: transparent linear-gradient(-270deg, #FFFFFF 0%, #80808000 100%) 0% 0% no-repeat padding-box;
	margin: 0 0 0 10px !important;

}


.orb-canvas {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	pointer-events: none;
	z-index: -1;
	background: #80808000 !important;
	/* background: -webkit-linear-gradient(to right, #80808000,  #400475, #80808000); */
	background: linear-gradient(to right, #80808000, #400475, #80808000);
}

/* ============== train-siri =========== */
.train-siri{
	position: relative;
	.iphone{
		height: 680px;
	}
	.siri-logo{
		width: 90px;
		position: absolute;
		bottom: 0px;
		margin: auto;
		left: 0;
		right: 0;
		opacity: 0;
	}
	.user-text{
		width: 300px;
		position: absolute;
		margin: auto;
		left: 0;
		right: -150px;
		top: 100px;
		opacity: 0;
	}
	.siri-text{
		width: 300px;
		position: absolute;
		margin: auto;
		left: 80px;
		top: 190px;
		opacity: 0;
	}
}



/* ============== about-wrapper =========== */
.about-wrapper {
	padding: 50px 0;
}

.team-wrapper {
	padding: 60px 0 50px;
}

.team-wrapper .team-img {
	border-radius: 6px;
}

.team-top-div {
	position: relative;
}

.realtor-logo {
	position: absolute;
	right: 40px;
	top: -37px;
}

.faq-wrapper {
	padding: 30px 0 80px;
}

.faq-list {
	border-radius: 0px;
	margin-bottom: -0.125rem;
}


.faq-list li {
	padding: 0.75rem 0.938rem;
}

.faq-list a {
	display: block;
	position: relative;
	padding-right: 1.563rem;
	cursor: pointer;
}


.faq-list .fas {
	display: inline-flex;
	justify-content: center;
	position: absolute;
	right: 0;
	top: 0.313rem;
	border: 1px solid #FFFFFF;
	border-radius: 50%;
	padding: 3px;
	font-size: 6px;
	color: #FFFFFF;
}

.faq-list h6 {
	font-size: 1rem;
	color: #FFFFFF;
	margin-bottom: 0;
}

.faq-list p {
	margin-bottom: 0;
	padding: 1rem 8px;
	color: #FFFFFF;
	text-align: start;

}

.faq-list .icon-show {
	display: none;
}

.faq-list a.collapsed .icon-show {
	display: inline-block;
}

.faq-list a.collapsed .icon-close {
	display: none;
}

@media (max-width : 1024px) {
	.realtor-logo {
		position: absolute;
		right: -44px;
	}

}

@media (max-width : 912px) {
	.realtor-logo {
		position: absolute;
		right: 73px;
		top: -36px;
	}

}

@media (max-width : 525px) {
	h1 {
		font-size: 50px;
	}

	.realtor-logo {
		display: none;
	}

	.home-btn-btn {
		font-size: 0.6rem;
		padding-bottom: 4px;
	}

	.fa-arrow-right {
		padding: 6px;

	}

	.fa-brands {
		padding: 5px 7px;
	}
	.banner-wrapper {
		.banner-img{
			opacity: 0;
			margin-top: 100px;
			height: 560px;
		}
		padding: 150px 0px;
		position: relative;
		h1 {
			font-size: 48px;
			text-align: center;
		}

		p {
			text-align: center;
			margin: 0px auto;
		}

		.btn {
			margin-left: 70px;

			&__circle,
			&__text,
			&__white-circle {
				position: absolute;
			}

			&__circle {
				top: 0;
				left: 0;
				height: 100%;
				border-radius: 100%;
				width: 100%;
				box-shadow: 0 0 1px 1px #fff;
				transition: 0.3s linear;
			}

			&__white-circle {
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%) scale(0);
				width: 56px;
				height: 56px;
				border-radius: 100%;
				background: #ffffff;
				display: flex;
				transition: 0.3s ease-in-out;

				svg {
					width: 24px;
					height: 24px;
					margin: auto;
				}
			}

			&__text {
				top: 50%;
				transform: translateY(-50%);
				white-space: nowrap;
				z-index: 2;
				padding: 24px 8px;
				transition: 0.3s linear;
			}

			&:hover {
				.btn__circle {
					transform: scale(0);
				}

				.btn__white-circle {
					transform: translate(-50%, -50%) scale(1);
				}

				.btn__text {
					transform: translate(40px, -50%);
				}
			}
		}

		.banner-img-1 {
			width: 120px;
			height: 280px;
		}

		.banner-img-2 {
			width: 120px;
			height: 280px;
		}
	}
	.train-siri{
		position: relative;
		.iphone{
			height: 680px;
		}
		.siri-logo{
			width: 90px;
			position: absolute;
			bottom: 0px;
			margin: auto;
			left: 0;
			right: 0;
			opacity: 0;
		}
		.user-text{
			width: 300px;
			position: absolute;
			margin: auto;
			left: 0;
			right: -100px;
			top: 100px;
			opacity: 0;
		}
		.siri-text{
			width: 300px;
			position: absolute;
			margin: auto;
			left: 10px;
			top: 190px;
			opacity: 0;
		}
	}
}



/* ============ footer-wrapper ============= */
.footer-wrapper {
	padding: 120px 0 100px;
}